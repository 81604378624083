var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card-code',{attrs:{"title":((_vm.$t('menu.nomenclador')) + " / " + (_vm.$t('menu.hotels')) + " / " + (_vm.$t('menu.conditionGenerals')))}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'conditions-generals-hotels-list' })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeft))]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.back')))])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[(!_vm.$store.state.app.onlyShow)?_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_c('span',[_vm._v(_vm._s(_vm.btnTitle))]),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiUpdate))])],1):_vm._e()],1)],1),(!_vm.isLoading)?_c('v-form',{staticClass:"multi-col-validation"},[_c('v-row',{staticClass:"pb-5"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsProveedor,"search-input":_vm.searchProveedor,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.proveedor'),"outlined":"","dense":"","item-text":"name_comercial","item-value":"id"},on:{"update:searchInput":function($event){_vm.searchProveedor=$event},"update:search-input":function($event){_vm.searchProveedor=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.proveedor'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name_comercial)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name_comercial))])],1)]}}],null,false,3152151403),model:{value:(_vm.item.proveedor_id),callback:function ($$v) {_vm.$set(_vm.item, "proveedor_id", $$v)},expression:"item.proveedor_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"items":_vm.categories,"item-text":"name","item-value":"id","label":_vm.$t('lbl.cat'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.item.category_id),callback:function ($$v) {_vm.$set(_vm.item, "category_id", $$v)},expression:"item.category_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.reference'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}},[_c('v-tooltip',{attrs:{"slot":"append-outer","top":""},slot:"append-outer",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pb-3",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])],1)]}}],null,false,1428075054)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.nameReference')))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"placeholder":((_vm.$t('lbl.description')) + "..."),"disabled":_vm.$store.state.app.onlyShow},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }